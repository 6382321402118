import { BrandID, BrandSetting } from './types';

const defaultConfig = {
  styles: {},
  content: {
    termsUrl: 'https://www.loopnet.com/solutions/terms-and-conditions/',
    logoSrc: 'https://www.loopnet.com/Modules/Header/Content/Images/header-logo-loopnet.svg',
    feedbackEmbed:
      'https://forms.office.com/Pages/ResponsePage.aspx?id=yudkmj82HESap0-Fl3wJ8XEkEDFeqVlEjv06wJcHbXBURU1EMkczSEUzT0JaM1gwTkFRMVlCRzJMQy4u&embed=true',
  },
};

const brands: BrandSetting[] = [
  {
    name: 'LoopNet',
    id: BrandID.LoopNet,
    host: '.loopnet.com',
    ...defaultConfig,
    colors: {
      signature: '#F22F46',
    },
  },
  {
    name: 'realla',
    id: BrandID.Realla,
    host: '.realla.co.uk',
    ...defaultConfig,
    colors: {
      signature: '#5abb57',
    },
    content: {
      ...defaultConfig.content,
      welcome: `Welcome to Realla's CoTour experience, a virtual meeting room where you can browse properties in
      real time with your team, on the UK's most visited commercial property website. Enjoy your
      CoTour.`,
      moderatorWelcome: `Welcome to Realla’s CoTour experience, a virtual meeting room where you can invite people from
      around the world to browse commercial real estate with you. Enter your name, invite your people,
      and enjoy your CoTour.`,
      termsUrl: 'https://www.realla.co.uk/terms-and-conditions/',
      logoSrc: '/images/Realla-Cotour@2x.png',
      fullWidthLogo: true,
      feedbackEmbed:
        'https://forms.office.com/Pages/ResponsePage.aspx?id=yudkmj82HESap0-Fl3wJ8TiyFaZTwXBOieRgjSMHQihUQTAzTFJLWlEwTEJFWFhaRElORDVQUjRFNC4u&embed=true',
      thankYou: 'Your meeting has ended, we hope you enjoyed your Realla CoTour.',
    },
  },
];

export const brandFromHost = (hostname: string): BrandSetting => {
  const brand = brands.find(({ host }) => hostname.endsWith(host)) || brands[0];
  return brand;
};
