import React, { ChangeEvent, FormEvent, useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import BrandedLogo from '../Branding/BrandedLogo/BrandedLogo';
import { useAppState } from '../../state';
import { useLocation, useHistory } from 'react-router-dom';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import '../../content/styles.scss';
import BrandedContent from '../Branding/BrandedContent/BrandedContent';
import BrandedTerms from '../Branding/BrandedTerms/BrandedTerms';
var DetectRTC = require('detectrtc');

export default function Join() {
  const { user, getToken, isFetching, sendInvites, isSendingInvites } = useAppState();
  const { isConnecting, connect } = useVideoContext();
  const roomState = useRoomState();

  const [name, setName] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [accessCode, setAccessCode] = useState<string>('');
  const [inviteEmails, setInviteEmails] = useState<string[]>(['']);
  const [isModerator, setIsModerator] = useState<boolean>(false);
  const [isCompatibleBrowser, setIsCompatibleBrowser] = useState<boolean>(true);
  const [headerInviteToggle, setHeaderInviteToggle] = useState<boolean>(false);
  const [headerInviteEmail, setHeaderInviteEmail] = useState<string>('');

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let history = useHistory();
  let query = useQuery();

  useEffect(() => {
    const URLRoomName = query.get('room') || '';
    if (URLRoomName) {
      setRoomName(URLRoomName);
    }

    const URLCode = query.get('code') || '';
    if (URLCode) {
      window.sessionStorage.setItem(URLRoomName, URLCode);
    }

    const savedCode = window.sessionStorage.getItem(URLRoomName) || '';
    if (savedCode) {
      setCode(savedCode);
      setIsModerator(true);
    }

    if (DetectRTC.isWebRTCSupported === false) {
      setIsCompatibleBrowser(false);
    }

    // one time redirect
    if (URLCode && URLRoomName) {
      history.push(`/?room=${URLRoomName}`);
    }
  }, [history, query]);

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleAccessCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAccessCode(event.target.value);
    setCode(event.target.value);
  };

  const handleAddInvite = (event: React.MouseEvent<HTMLButtonElement>) => {
    // add additional invite field
    setInviteEmails([...inviteEmails, '']);
  };

  const handleInviteChange = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedInviteEmails = [...inviteEmails];
    updatedInviteEmails[event.target.dataset.idx || 0] = event.target.value;
    setInviteEmails(updatedInviteEmails);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const nonEmtpyEmails = inviteEmails.filter(x => x.length > 0);
    if (nonEmtpyEmails.length > 0) {
      sendInvites(roomName, code, nonEmtpyEmails);
    }

    getToken(name, roomName, code).then(token => connect(token));
  };

  const handleHeaderInviteEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setHeaderInviteEmail(event.target.value);
  };

  const handleHeaderInviteToggleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    setHeaderInviteToggle(false);
  };

  const handleHeaderInviteToggleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setHeaderInviteToggle(true);
  };

  const handleHeaderInviteSubmit = (event: React.MouseEvent<HTMLFormElement>) => {
    // Send the email invite
    event.preventDefault();

    setHeaderInviteToggle(false);
    setHeaderInviteEmail('');

    if (headerInviteEmail) {
      sendInvites(roomName, code, [headerInviteEmail]);
    }
  };

  return (
    <>
      <header>
        <BrandedLogo />
        {roomState === 'connected' && isModerator && (
          <>
            {headerInviteToggle ? (
              <div className="add-friend-wrapper room-email-field">
                <button
                  className="ln-icon-close-hollow"
                  onClick={handleHeaderInviteToggleClose}
                  cbl-ga-data-on="click"
                  cbl-ga-data-event-category="cotouring"
                  cbl-ga-data-event-action="Invite Toggle Close"
                ></button>
                <form onSubmit={handleHeaderInviteSubmit}>
                  <input
                    type="email"
                    value={headerInviteEmail}
                    onChange={handleHeaderInviteEmailChange}
                    className="text-field"
                    placeholder="Enter email address"
                  />
                  <button
                    type="submit"
                    disabled={!headerInviteEmail}
                    cbl-ga-data-on="click"
                    cbl-ga-data-event-category="cotouring"
                    cbl-ga-data-event-action="Invite Send"
                  >
                    Send
                  </button>
                </form>
              </div>
            ) : (
              <button
                className="room-email-invite"
                onClick={handleHeaderInviteToggleOpen}
                cbl-ga-data-on="click"
                cbl-ga-data-event-category="cotouring"
                cbl-ga-data-event-action="Invite Toggle Open"
              >
                <span className="icons">
                  <span className="ln-icon-myprofile-filled"></span>
                  <span className="ln-icon-plus-filled"></span>
                </span>
                <span className="invite-text">Invite</span>
              </button>
            )}
          </>
        )}
      </header>

      <div className="container">
        <div>
          {roomState === 'disconnected' ? (
            <div className="form-container">
              <form className="form" onSubmit={handleSubmit}>
                <div className="welcome">
                  {isModerator ? (
                    <h2>
                      <BrandedContent contentKey="moderatorWelcome">
                        Welcome to LoopNet’s CoTour experience, a virtual meeting room where you can invite people from
                        around the world to browse commercial real estate with you. Enter your name, invite your people,
                        and enjoy your CoTour.
                      </BrandedContent>
                    </h2>
                  ) : (
                    <h2>
                      <BrandedContent contentKey="welcome">
                        Welcome to LoopNet's CoTour experience, a virtual meeting room where you can browse properties
                        in real time with your team, on the world's most visited commercial real estate website. Enjoy
                        your CoTour.
                      </BrandedContent>
                    </h2>
                  )}
                  <p className="please-accept">
                    Please click accept when prompted to give CoTour access to your microphone and camera.
                  </p>
                </div>
                <div className="field-wrapper">
                  <div className={'name-section'}>
                    <div>
                      {!user?.displayName ? (
                        <label className="label">
                          Enter Your Name
                          <br />
                          <input
                            type="text"
                            className="text-field"
                            placeholder="Name"
                            value={name}
                            onChange={handleNameChange}
                          />
                        </label>
                      ) : (
                        <span className="display-name">{user.displayName}</span>
                      )}
                    </div>
                  </div>

                  <div className="room-section">
                    {isModerator ? (
                      <div>
                        <label className="label">
                          Add People
                          <br />
                          {inviteEmails.map((val, idx) => {
                            const emailId = `email-${idx}`;
                            return (
                              <div key={`invite-${idx}`} className="add-friend-wrapper">
                                <input
                                  type="email"
                                  name={emailId}
                                  value={inviteEmails[idx]}
                                  onChange={handleInviteChange}
                                  data-idx={idx}
                                  id={emailId}
                                  className="email text-field"
                                  placeholder="Email address"
                                />
                              </div>
                            );
                          })}
                        </label>

                        <button
                          type="button"
                          className="add-friends"
                          onClick={handleAddInvite}
                          cbl-ga-data-on="click"
                          cbl-ga-data-event-category="cotouring"
                          cbl-ga-data-event-action="Add People"
                        >
                          <span className="ln-icon-employees"></span>
                          <span className="ln-icon-plus-filled"></span>
                        </button>
                      </div>
                    ) : (
                      <div className="access-code">
                        <label className="label">
                          Enter Access Code
                          <br />
                          <input
                            type="text"
                            className="text-field"
                            value={accessCode}
                            onChange={handleAccessCodeChange}
                          />
                        </label>
                      </div>
                    )}
                  </div>
                </div>
                {isCompatibleBrowser ? (
                  <>
                    <div className="button-wrapper desktop">
                      <button
                        type="submit"
                        cbl-ga-data-on="click"
                        cbl-ga-data-event-category="cotouring"
                        cbl-ga-data-event-action={isModerator ? 'Start Meeting' : 'Join Meeting'}
                        disabled={
                          isModerator
                            ? isConnecting || !name || isFetching || isSendingInvites
                            : isConnecting || !name || isFetching || isSendingInvites || !accessCode
                        }
                        className="submit-button"
                      >
                        {isModerator ? 'Start Meeting' : 'Join Meeting'}
                      </button>
                      <BrandedTerms />
                    </div>
                    <div className="button-wrapper mobile">
                      <button
                        type="submit"
                        cbl-ga-data-on="click"
                        cbl-ga-data-event-category="cotouring"
                        cbl-ga-data-event-action={isModerator ? 'Start Meeting' : 'Join Meeting'}
                        disabled={
                          isModerator
                            ? isConnecting || !name || isFetching || isSendingInvites
                            : isConnecting || !name || isFetching || isSendingInvites || !accessCode
                        }
                        className="submit-button"
                      >
                        {isModerator ? 'Start Meeting' : 'Join Meeting'}
                      </button>
                      <BrandedTerms />
                    </div>
                  </>
                ) : (
                  <div className="browser-message">
                    <p>Please make sure you are using Chrome, Safari, or Firefox.</p>
                  </div>
                )}
              </form>
              <div className="spinner-container">
                {(isConnecting || isFetching || isSendingInvites) && <CircularProgress className="loading-spinner" />}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}
