import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import App from './App';
import AppStateProvider, { useAppState } from './state';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ConnectOptions } from 'twilio-video';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import theme from './theme';
import './types';
import { VideoProvider } from './components/VideoProvider';
import UnavailablePage from './components/UnavailablePage/UnavailablePage';
import ThankYouPage from './components/ThankYouPage/ThankYouPage';
import TimeoutPage from './components/TimeoutPage/TimeoutPage';

import TagManager from 'react-gtm-module';
import { brandFromHost } from './branding';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID || 'GTM-W7MT32P',
};

TagManager.initialize(tagManagerArgs);

const branding = brandFromHost(window.location.hostname);

// See: https://media.twiliocdn.com/sdk/js/video/releases/2.0.0/docs/global.html#ConnectOptions
// for available connection options.
const connectionOptions: ConnectOptions = {
  bandwidthProfile: {
    video: {
      mode: 'collaboration',
      dominantSpeakerPriority: 'standard',
      renderDimensions: {
        high: { height: 1080, width: 1920 },
        standard: { height: 720, width: 1280 },
        low: { height: 90, width: 160 },
      },
    },
  },
  dominantSpeaker: true,
  maxAudioBitrate: 12000,
  networkQuality: { local: 1, remote: 1 },
  preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
};

const VideoApp = () => {
  const { error, setError } = useAppState();

  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <ErrorDialog dismissError={() => setError(null)} error={error} />
      <App />
    </VideoProvider>
  );
};

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <Router>
      <AppStateProvider branding={branding}>
        <Switch>
          <Route path="/unavailable">
            <UnavailablePage />
          </Route>
          <Route path="/thank-you">
            <ThankYouPage />
          </Route>
          <Route path="/timeout">
            <TimeoutPage />
          </Route>
          <Route path="/">
            <VideoApp />
          </Route>
        </Switch>
      </AppStateProvider>
    </Router>
  </MuiThemeProvider>,
  document.getElementById('root')
);
