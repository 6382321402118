import React from 'react';
import { styled } from '@material-ui/core/styles';
import '../../content/styles.scss';
import BrandedLogo from '../Branding/BrandedLogo/BrandedLogo';
import BrandedContent from '../Branding/BrandedContent/BrandedContent';
import BrandedFeedback from '../Branding/BrandedFeedback/BrandedFeedback';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '92vh',
  backgroundColor: '#fff',
});

export default function ThankYouPage() {
  return (
    <Container>
      <header>
        <BrandedLogo />
      </header>
      <div className="container">
        <div>
          <div className="form-container alt-page">
            <div className="form">
              <div className="field-wrapper alt-page">
                <h2>
                  <BrandedContent contentKey="thankYou">
                    Your meeting has ended, we hope you enjoyed your LoopNet CoTour.
                  </BrandedContent>
                </h2>
              </div>
              <BrandedFeedback />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
