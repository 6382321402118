import { useAppState } from '../../../state';
import React, { Fragment, ReactElement } from 'react';

const LogoDivider = (
  <Fragment>
    <div className="logo-divider"></div>
    <h1>
      <span className="ln-icon-employees"></span>CoTour
    </h1>
  </Fragment>
);

function BrandedLogo(): ReactElement {
  const { branding } = useAppState();
  const src = branding.content['logoSrc'];
  const fullWidthLogo: boolean = !!branding.content['fullWidthLogo'];
  const classNames: string = fullWidthLogo ? 'full-width' : '';
  return (
    <Fragment>
      <img src={src} alt="" className={classNames} />
      {!fullWidthLogo ? LogoDivider : null}
    </Fragment>
  );
}

export default BrandedLogo;
