import { useAppState } from '../../../state';
import React, { ReactElement } from 'react';

interface EmbedProps {
  url: string;
}

const Embed = ({ url }: EmbedProps): ReactElement => (
  <div className="field-wrapper alt-page">
    <iframe
      title="feedbackFormEmbed"
      width="600px"
      height="1100px"
      src={url}
      frameBorder="1"
      marginWidth={0}
      marginHeight={0}
      style={{ border: 'none', width: '100%', maxWidth: '100%', maxHeight: '100vh', textAlign: 'justify' }}
      allowFullScreen
    ></iframe>
  </div>
);

function BrandedFeedback(): ReactElement | null {
  const { branding } = useAppState();
  const url: string = branding.content['feedbackEmbed'];
  return url ? <Embed url={url} /> : null;
}

export default BrandedFeedback;
