import React from 'react';
import { styled } from '@material-ui/core/styles';
import '../../content/styles.scss';
import BrandedLogo from '../Branding/BrandedLogo/BrandedLogo';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '92vh',
  backgroundColor: '#fff',
});

export default function TimeoutPage() {
  return (
    <>
      <Container>
        <header>
          <BrandedLogo />
        </header>
        <div className="container">
          <div>
            <div className="form-container alt-page">
              <div className="form">
                <div className="field-wrapper alt-page">
                  <h2>
                    Your meeting room time has expired. If you would like to continue, please start another meeting.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
