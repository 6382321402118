import React, { createContext, useContext, useState } from 'react';
import { TwilioError } from 'twilio-video';
import { User } from 'firebase';
import { BrandSetting } from '../types';

export interface StateContextType {
  error: TwilioError | null;
  setError(error: TwilioError | null): void;
  getToken(name: string, room: string, code: string): Promise<string>;
  sendInvites(room: string, code: string, invitees: Array<string>): Promise<string>;
  branding: BrandSetting;
  user?: User | null | { displayName: undefined; photoURL: undefined; passcode?: string };
  signIn?(passcode?: string): Promise<void>;
  signOut?(): Promise<void>;
  isAuthReady?: boolean;
  isFetching: boolean;
  isSendingInvites: boolean;
}

export const StateContext = createContext<StateContextType>(null!);

export default function AppStateProvider(props: React.PropsWithChildren<{ branding: BrandSetting }>) {
  const [error, setError] = useState<TwilioError | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isSendingInvites, setIsSendingInvites] = useState(false);
  const { branding } = props;
  let contextValue = {
    error,
    setError,
    isFetching,
    isSendingInvites,
    branding,
  } as StateContextType;

  contextValue = {
    ...contextValue,
    getToken: async (identity, roomName, code) => {
      const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT || '/token';
      const request = { roomId: roomName, roomCode: code, participant: identity };
      var headers = { Accept: 'application/json', 'Content-Type': 'application/json' };
      const response = fetch(`${endpoint}`, { method: 'post', headers: headers, body: JSON.stringify(request) })
        .then(res => res.json())
        .then(json => json.accessToken)
        .catch(err => {
          console.error(err);
          return 'bad token';
        });
      return response;
    },
    sendInvites: async (roomName, code, invitees) => {
      const endpoint = process.env.REACT_APP_INVITE_ENDPOINT || '/Invite';
      const request = { roomId: roomName, roomCode: code, invitees: invitees };
      var headers = { Accept: 'application/json', 'Content-Type': 'application/json' };
      const response = fetch(`${endpoint}`, { method: 'post', headers: headers, body: JSON.stringify(request) })
        .then(res => {
          console.log(res);
          return 'invite success';
        })
        .catch(err => {
          console.error(err);
          return 'invite failed';
        });
      return response;
    },
  };

  const getToken: StateContextType['getToken'] = (name, room, code) => {
    setIsFetching(true);
    return contextValue
      .getToken(name, room, code)
      .then(res => {
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const sendInvites: StateContextType['sendInvites'] = (room, code, invitees) => {
    setIsSendingInvites(true);
    return contextValue
      .sendInvites(room, code, invitees)
      .then(res => {
        setIsSendingInvites(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsSendingInvites(false);
        return Promise.reject(err);
      });
  };

  return (
    <StateContext.Provider value={{ ...contextValue, getToken, sendInvites }}>
      <div className={`brand-${branding.name}`}>{props.children}</div>
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
