import { useAppState } from '../../../state';
import React, { ReactElement, ReactNode } from 'react';

interface BrandedContentProps {
  contentKey: string;
  children?: ReactNode;
}
function BrandedContent({ children, contentKey }: BrandedContentProps): ReactElement {
  const { branding } = useAppState();
  const missing: ReactElement = <div>No branded content or default value {contentKey}</div>;
  return (branding.content && branding.content[contentKey]) || children || missing;
}

export default BrandedContent;
