import React from 'react';
import ParticipantStrip from '../ParticipantStrip/ParticipantStrip';
import MainParticipant from '../MainParticipant/MainParticipant';
import '../../content/styles.scss';
import Controls from '../Controls/Controls';

export default function Room() {
  return (
    <div className="room-container">
      <div className="participant-strip-container">
        <ParticipantStrip />
      </div>
      <div className="main-participant-container">
        <MainParticipant />
        <Controls />
      </div>
    </div>
  );
}
