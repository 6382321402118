import React from 'react';
import { LocalVideoTrack } from 'twilio-video';
import VideoTrack from '../VideoTrack/VideoTrack';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import Controls from '../Controls/Controls';

export default function LocalVideoPreview() {
  const { localTracks } = useVideoContext();

  const videoTrack = localTracks.find(track => track.name === 'camera') as LocalVideoTrack;

  return videoTrack ? (
    <div>
      <div className="video-preview-window">
        <VideoTrack track={videoTrack} isLocal />
        <span className="preview-controls">
          <Controls />
        </span>
      </div>
    </div>
  ) : (
    <div className="no-video">
      <span className="ln-icon-myprofile-filled"></span>
      <span className="preview-controls">
        <Controls />
      </span>
    </div>
  );
}
