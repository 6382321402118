import React from 'react';
import { styled } from '@material-ui/core/styles';

import LocalVideoPreview from './components/LocalVideoPreview/LocalVideoPreview';
import Join from './components/Join/Join';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import Room from './components/Room/Room';

import useRoomState from './hooks/useRoomState/useRoomState';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '92vh',
});

const Main = styled('main')({
  height: '100%',
  position: 'relative',
});

export default function App() {
  const roomState = useRoomState();

  return (
    <Container>
      <Join />
      <Main>{roomState === 'disconnected' ? <LocalVideoPreview /> : <Room />}</Main>
      <ReconnectingNotification />
    </Container>
  );
}
