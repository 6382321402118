import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import CallEnd from '@material-ui/icons/CallEnd';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
    },
  })
);

export default function EndCallButton() {
  const classes = useStyles();
  const { room } = useVideoContext();
  let history = useHistory();

  const handleEndCall = (event: React.MouseEvent<HTMLElement>) => {
    room.disconnect();
    history.push('/thank-you');
  };

  return (
    <Tooltip title={'End Call'} onClick={handleEndCall} placement="top" PopperProps={{ disablePortal: true }}>
      <Fab
        className={classes.fab}
        color="primary"
        cbl-ga-data-on="click"
        cbl-ga-data-event-category="cotouring"
        cbl-ga-data-event-action="End Call"
      >
        <CallEnd />
      </Fab>
    </Tooltip>
  );
}
