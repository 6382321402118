import { useAppState } from '../../../state';
import React, { ReactElement } from 'react';

function BrandedTerms(): ReactElement {
  const { branding } = useAppState();
  const url = branding.content['termsUrl'];
  return (
    <p className="terms">
      I accept {branding.name}'s <a href={url}>Terms of Use</a>.
    </p>
  );
}

export default BrandedTerms;
